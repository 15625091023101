import React, { useState, useEffect } from 'react'
import Layout from '../layout/Layout'
import { useNavigate, useParams } from 'react-router-dom';
import { editQuiz, getOneQuiz, getQuizMl } from '../../services/quiz-api';
import { languages } from '../../utils/constants';

const EditQuiz = () => {

    const [state, setState] = useState({
        name: "",
        description: "",
        isPopular: false,
        isFeatured: false,
        image: "",
        languageId: "0",
    });
    const navigate = useNavigate();
    const [previewImage, setPreviewImage] = useState(null);
    const { id } = useParams();

    const onSubmit = async (event) => {
        event.preventDefault();

        console.log("state on edit ", state);

        //check validation
        if (state.name) {
            //call services api
            await editQuiz(id, {
                ...state
            }).then(res => {
                navigate('/quiz')
            }).catch(err => {
                console.log(err)
            })
        }
    }

    useEffect(() => {
        getOneQuiz(id).then((res) => {
            console.log("res ", res);
            const { language_id, name, description } = res;
            const { isPopular, isFeatured } = res;
            setState({
                ...state,
                name,
                description,
                isPopular,
                isFeatured,
                languageId: language_id,
            })
            setPreviewImage(res.image)
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                state.image = reader.result
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleOnChange = async (quizId, languageId) => {
        console.log("Front ", quizId);
        console.log("LanguageId ", languageId);

        await getQuizMl(quizId, languageId).then((res) => {
            console.log("res ", res);
            if (res != null) {
                const { name, description, isPopular, isFeatured, language_id } = res;
                setState({
                    ...state,
                    name,
                    description,
                    isPopular,
                    isFeatured,
                    languageId: language_id
                })
            } else {
                console.log("here")
                setState({
                    ...state,
                    name: "",
                    description: "",
                    languageId
                })
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        console.log("state ", state);
    })

    return (
        <>
            <Layout>
                <div className="d-flex">
                    <h2>Edit Quiz</h2>
                </div>

                <div className="authincation-content">
                    <section className="login-part">
                        <div className="container">
                            <div className='login'>
                                <div className='login-card'>
                                    <div className='card'>
                                        <form onSubmit={onSubmit}>
                                            <div className='card-body'>
                                                <div className="mb-4">
                                                    <label htmlFor="language" className="form-label">
                                                        Select Language
                                                    </label>
                                                    <select id="language" className="form-control" onChange={(event) => {
                                                            setState({ ...state, languageId: event.target.value });
                                                            handleOnChange(id, event.target.value);
                                                    }}>
                                                        {languages.map(language => (
                                                            <option value={language.id}>{language.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="mb-4">
                                                    <label htmlFor="name" className="form-label">Name</label>
                                                    <input type="text" className="form-control"
                                                        id="name" placeholder="Enter name"
                                                        value={state.name}
                                                        onChange={(event) => setState({ ...state, name: event.target.value })}
                                                        autoComplete="off" />
                                                </div>
                                                <div className="mb-4">
                                                    <label htmlFor="description" className="form-label">Description</label>
                                                    <input type="text" className="form-control"
                                                        id="description" placeholder="Enter description"
                                                        value={state.description}
                                                        onChange={(event) => setState({ ...state, description: event.target.value })}
                                                        autoComplete="off" />
                                                </div>
                                                <div className="mb-4">
                                                    <input type="checkbox" className="form-check-input"
                                                        id="isPopular"
                                                        value={state.isPopular}
                                                        checked={state.isPopular}
                                                        onChange={(event) => setState({ ...state, isPopular: event.target.checked })}
                                                        autoComplete="off" />
                                                    <label htmlFor="isPopular" className="form-check-label">Popular</label>
                                                </div>
                                                <div className="mb-4">
                                                    <input type="checkbox" className="form-check-input"
                                                        id="isFeatured"
                                                        value={state.isFeatured}
                                                        checked={state.isFeatured}
                                                        onChange={(event) => setState({ ...state, isFeatured: event.target.checked })}
                                                        autoComplete="off" />
                                                    <label htmlFor="isFeatured" className="form-check-label">Featured</label>
                                                </div>
                                                <div class="mb-4">
                                                    <label for="formFile" class="form-label">Select File</label>
                                                    <input class="form-control" type="file" id="formFile" onChange={handleFileChange} />
                                                    {previewImage && (
                                                        <div className='img-container'>
                                                            <img
                                                                src={previewImage}
                                                                alt="Preview"
                                                                style={{ maxWidth: '200px', marginTop: '10px' }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="form-login-button pt-4">
                                                    <button type="submit">Save</button>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
            </Layout>
        </>
    )
}

export default EditQuiz